





































































































































import {forEach} from '@labor-digital/helferlein';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {isString} from '@labor-digital/helferlein/lib/Types/isString';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {AppStoreKeys} from '../../../../AppStoreKeys';
import CurrentBranch from '../../../../Component/Branch/CurrentBranch/CurrentBranch.vue';
import Contact from '../../../../Component/Forms/Contact/Contact.vue';
import SaveJobAction from '../../../../Component/Job/JobMetaActions/SaveJobAction/SaveJobAction.vue';
import Btn from '../../../../Component/Misc/Btn/Btn.vue';
import Icon from '../../../../Component/Misc/Icon/Icon.vue';
import ShareButton from '../../../../Component/Misc/ShareButton/ShareButton.vue';
import {Branch} from '../../../../Interface/Branch';
import {Job} from '../../../../Interface/Job';
import LanguageSwitchMixin from '../../../../Mixin/LanguageSwitchMixin';
import OpenGraphMixin from '../../../../Mixin/OpenGraphMixin';
import {DateFormatter} from '../../../../Service/DateFormatter';
import {EtrackerService} from '../../../../Service/EtrackerService';
import {GoogleTagManagerService} from '../../../../Service/GoogleTagManagerService';
import {JsonLdService} from '../../../../Service/JsonLdService';
import Headline from '../../Headline/Headline.vue';

export default {
    name: 'JobSearchDetail',
    components: {ShareButton, Contact, Headline, Icon, CurrentBranch, Btn, SaveJobAction},
    mixins: [LanguageSwitchMixin, OpenGraphMixin],
    props: {
        context: null as ContentElementContext
    },
    metaInfo()
    {
        return {
            title: this.ogTitle,
            meta: this.metaTags.concat([
                {'name': 'description', 'content': this.ogDescription, 'vmid': 't3-description'},
                {'name': 'image', 'content': this.ogImage, 'vmid': 'image'}
            ]),
            script: [
                JsonLdService.makeBreadcrumb(this.job.title),
                JsonLdService.makeJobOffer(this.job, this.ogDescription)
            ]
        };
    },
    computed: {
        job(): Job
        {
            return this.context.initialState.getAll();
        },
        aivyLink(): string | null
        {
            return isEmpty(this.job.aivyLink) ? null : this.job.aivyLink;
        },
        pitchYou(): string | null
        {
            return isEmpty(this.job.pitchYou) ? null : this.job.pitchYou;
        },
        bookMy(): string | null
        {
            return isEmpty(this.job.bookMe) ? null : this.job.bookMe;
        },
        xingLink(): string | null
        {
            return isEmpty(this.job.xing) ? null : this.job.xing;
        },
        branch(): Branch | null
        {
            return this.context.initialState.get('branch');
        },
        hasContractType(): boolean
        {
            return !isEmpty(this.job.contractType);
        },
        ogTitle(): string
        {
            return this.getOgTitle(this.$t('jobSearch.detail.title', [this.job.title, this.job.city]));
        },
        ogDescription(): string
        {
            return this.getOgDescription(this.$t('jobSearch.detail.description', [this.job.title, this.job.city]));
        },
        ogImage(): string
        {
            return this.job.image;
        }
    },
    methods: {
        formatDate(date: string)
        {
            const currentDate = new Date();
            const now = new Date(date);
            if (now < currentDate) {
                return this.$t('dates.now');
            }
            return DateFormatter.format(date);
        },

        onApplicationClick(link: string)
        {
            EtrackerService.trackClick('Application click: ' + this.job.id + ' - ' + this.job.title);
            GoogleTagManagerService.track('application_click', this.job.id + ' - ' + this.job.title);
        },
        buildDefinitions()
        {
            const sizes = {
                imageXs: 576,
                imageSm: 768,
                imageMd: 992,
                imageLg: 1200,
                imageXl: 2048
            };
            let definitions = [];
            forEach(sizes, (size, key) => {
                if (!isEmpty(this.job[key])) {
                    definitions.push({
                        srcset: this.job[key],
                        media: `(max-width: ${size}px)`,
                        sizes: `${size}px`
                    });
                }
            });

            return definitions;
        }
    },
    created()
    {
        this.context.store.set(AppStoreKeys.PAGE_HEADLINE_OVERRIDE, this.job.title);

        let images: any = [
            {
                isStatic: true,
                url: this.job.image
            }
        ];

        const definitions = this.buildDefinitions();
        if (!isEmpty(definitions)) {
            images = {
                url: this.job.image,
                alt: this.job.title,
                definitions: this.buildDefinitions()
            };
        }

        this.context.store.set(AppStoreKeys.PAGE_TEASER_IMAGE_OVERRIDE,
                isString(this.job.image) ? images : undefined);
    }
};
